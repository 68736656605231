<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="gpath_skill_recoAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchgpath_skill_recoWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="gpath_skill_recoObjList && gpath_skill_recoObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpath_skill_recoObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(created_on)="data">
                    {{data.item.created_on | dateFormat}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="gpath_skill_recoEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showGpath_skill_recoDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelGpath_skill_recoAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <gpathSkillRecoAdd :propOpenedInModal="true" @emitCloseGpath_skill_recoAddModal="closeGpath_skill_recoAddModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeGpath_skill_recoAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelGpath_skill_recoEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <gpathSkillRecoEdit :propOpenedInModal="true" :propGpath_skill_recoObj="gpath_skill_recoEditObj" @emitCloseGpath_skill_recoEditModal="closeGpath_skill_recoEditModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeGpath_skill_recoEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelGpath_skill_recoDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelGpath_skill_recoDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="gpath_skill_recoDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { gpathSkillRecos } from "../../../FackApi/api/GpathSkillReco.js"
import gpathSkillRecoAdd from "./GpathSkillRecoAdd"
import gpathSkillRecoEdit from "./GpathSkillRecoEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"

export default {
  name: "gpathSkillRecoList",
  components: {
    gpathSkillRecoAdd,
    gpathSkillRecoEdit
  },
  data () {
    return {
      cvCardTitle: "GPath Skill Recos",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit GPath Skill Reco",
      cvAddModalHeader: "Add GPath Skill Reco",
      cvbtnModalCancel: "Cancel",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPath Skill Reco",
      showModelGpath_skill_recoAdd: false,
      showModelGpath_skill_recoEdit: false,
      showModelGpath_skill_recoDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Name", key: "skill_name", class: "text-left align-text-top", sortable: true },
        { label: "Desc", key: "skill_desc", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      gpath_skill_recoObjList: [],
      gpath_skill_recoEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {},
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 25,
      showLoadMoreBtn: true
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    this.gpath_skill_recoList()
  },
  methods: {
    /**
     * searchgpath_skill_recoWithParams
     */
    async searchgpath_skill_recoWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.gpath_skill_recoList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.gpath_skill_recoList(true)
    },
    /**
     * gpath_skill_recoList
     */
    async gpath_skill_recoList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param

        let gpathSkillRecoListResp = await gpathSkillRecos.gpath_skill_recoList(this, this.whereFilter)
        if (gpathSkillRecoListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.gpath_skill_recoObjList = [ ...gpathSkillRecoListResp.resp_data.data ]
          }
          else {
            this.gpath_skill_recoObjList = [ ...this.gpath_skill_recoObjList, ...gpathSkillRecoListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.gpath_skill_recoObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.gpath_skill_recoObjList.length
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = gpathSkillRecoListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_skill_recoList() and Exception:", err)
      }
    },
    /**
     * gpath_skill_recoAdd
     */
    gpath_skill_recoAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/gpath_skill_reco_add")
        }
        else {
          this.showModelGpath_skill_recoAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_skill_recoAdd() and Exception:", err.message)
      }
    },
    /**
     * gpath_skill_recoEdit
     */
    gpath_skill_recoEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/gpath_skill_reco_edit/" + this.gpath_skill_recoEditObj.gpsr_id)
        }
        else {
          this.gpath_skill_recoEditObj = item
          this.showModelGpath_skill_recoEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_skill_recoEdit() and Exception:", err.message)
      }
    },
    /**
     * showGpath_skill_recoDeleteDialog
     */
    showGpath_skill_recoDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelGpath_skill_recoDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showGpath_skill_recoDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * gpath_skill_recoDelete
     */
    async gpath_skill_recoDelete () {
      try {
        let gpathSkillRecoDelResp = await gpathSkillRecos.gpath_skill_recoDelete(this, this.delObj.gpsr_id)
        await ApiResponse.responseMessageDisplay(this, gpathSkillRecoDelResp)

        if (gpathSkillRecoDelResp && !gpathSkillRecoDelResp) {
          this.showModelGpath_skill_recoDelete = false
          return false
        }

        let index = this.gpath_skill_recoObjList.indexOf(this.delObj)
        this.gpath_skill_recoObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelGpath_skill_recoDelete = false
      }
      catch (err) {
        console.error("Exception occurred at gpath_skill_recoDelete() and Exception:", err.message)
      }
    },
    /**
     * closeGpath_skill_recoAddModal
     */
    closeGpath_skill_recoAddModal (gpathSkillRecoAddData) {
      try {
        if (gpathSkillRecoAddData) {
          if (this.gpath_skill_recoObjList && this.gpath_skill_recoObjList.length >= 1) {
            let gpathSkillRecoObjLength = this.gpath_skill_recoObjList.length
            let lastId = this.gpath_skill_recoObjList[gpathSkillRecoObjLength - 1]["id"]
            gpathSkillRecoAddData.id = lastId + 1
          }
          else {
            this.gpath_skill_recoObjList = []
            gpathSkillRecoAddData.id = 11111
          }

          gpathSkillRecoAddData.created_on = moment()
          this.gpath_skill_recoObjList.unshift(gpathSkillRecoAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGpath_skill_recoAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closegpath_skill_recoAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeGpath_skill_recoEditModal
     */
    closeGpath_skill_recoEditModal () {
      try {
        this.showModelGpath_skill_recoEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeGpath_skill_recoEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
